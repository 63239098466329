<template>
  <div class="relative z-10 container-bg flex flex-col text-center items-center p-4 md:p-10 2xl:p-20 m-8 lg:m-12 2xl:m-24">
    <h1 class="mb-9 2xl:text-5xl">Reset your password</h1>

    <div
      v-if="hasToken && errorMessage && typeof errorMessage === 'string'"
      class="mt-3 bg-red-300 border border-red-600 rounded-xl p-4 md:py-5 md:px-8 font-medium text-sm text-red-600 mb-8"
    >
      <span
        class="icon md:mr-3.5"
        v-html="icons.info"
      ></span>

      {{ errorMessage }}
    </div>

    <p
      v-if="!hasToken"
      class="text-sm lg:text-medium max-w-3xl text-grey-dark leading-7 mb-12"
    >
      We will send you a password reset link if your email exists in our system.
    </p>

    <form @submit.prevent="onSubmit" class="text-left w-full max-w-sm">
      <form-field-input
        v-model.trim="email"
        class="mb-8"
        size="medium"
        type="email"
        field-label="E-mail Address *"
        placeholder="example@dsm.com"
        :validation-class="{invalid: $v.email.$error, valid: !$v.email.$invalid}"
        :disabled="hasToken"
        @blur="$v.email.$touch()"
      >
        <template #helper v-if="$v.email.$error">
          <span
            v-if="!$v.email.required"
            class="invalid"
          >
            Email can't be empty
          </span>

            <span
              v-if="!$v.email.email"
              class="invalid"
            >
            Email isn't valid
          </span>
        </template>
      </form-field-input>

      <template
        v-if="hasToken"
      >
        <form-field-input
          v-model.trim="password"
          class="mb-8"
          size="medium"
          type="password"
          field-label="New Password *"
          placeholder="Enter Password"
          :validation-class="{invalid: $v.password.$error, valid: !$v.password.$invalid}"
          @blur="$v.password.$touch()"
        >
          <template #helper v-if="$v.password.$error">
            <span
              v-if="!$v.password.required"
              class="invalid"
            >
              Password can't be empty
            </span>

            <span
              v-if="!$v.password.minLength"
              class="invalid"
            >
                min Length is 6
            </span>
          </template>
        </form-field-input>

        <form-field-input
          v-model.trim="password_confirmation"
          class="mb-8"
          size="medium"
          type="password"
          field-label="New Password *"
          placeholder="Confirm Password"
          :validation-class="{invalid: $v.password_confirmation.$error, valid: $v.password_confirmation.sameAsPassword && !$v.password.$invalid}"
          @blur="$v.password_confirmation.$touch()"
        >
          <template #helper v-if="$v.password_confirmation.$error">
            <span
              v-if="!$v.password_confirmation.required"
              class="invalid"
            >
              Passwords must be identical
            </span>

            <span
              v-if="!$v.password_confirmation.minLength && $v.password_confirmation.sameAsPassword"
              class="invalid"
            >
              min Length is 6
            </span>
          </template>
        </form-field-input>
      </template>

      <button
        type="submit"
        class="btn-success w-full"
        :class="{'mb-5': !!message}"
      >
        {{ !hasToken ? 'Send Password Reset Link' : 'Reset Password' }}
      </button>
    </form>

    <div
      v-if="message"
      class="mt-3 bg-green-200 border border-green-600 rounded-xl p-4 md:py-5 md:px-8 font-medium text-sm text-green-600"
    >
      <span
        class="icon md:mr-3.5"
        v-html="icons.info"
      ></span>

      {{ message }}
    </div>
  </div>
</template>

<script>
import FormFieldInput from '@/components/form/FormFieldInput';
import { validationMixin } from 'vuelidate';
import {required, email, minLength, sameAs} from 'vuelidate/lib/validators';

export default {
  name: 'ResetPassword',

  components: {
    FormFieldInput
  },

  mixins: [validationMixin],

  validations() {
    if (this.hasToken) {
      return {
        email: {
          required,
          email
        },

        password: {
          required,
          minLength: minLength(6),
        },

        password_confirmation: {
          sameAsPassword: sameAs('password'),
        }
      }
    } else {
      return {
        email: {
          required,
          email
        }
      }
    }
  },

  props: {
    token: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    email: '',
    password: '12345678',
    password_confirmation: '12345678',
    message: '',
    errorMessage: ''
  }),

  mounted() {
    this.email = this.$route.query.email
  },

  methods: {
    onSubmit() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.message = ''

      const formData = {
        email: this.email
      }

      let url = 'forgot-password'

      if (this.hasToken) {
        /* eslint-disable camelcase */
        const { password, token, password_confirmation } = this
        Object.assign(formData, {password, token, password_confirmation})
        url = 'reset-password'
      }

      this.$api.get('/sanctum/csrf-cookie')
          .then(() => {
            this.$api.post(`/${url}`, formData)
                .then(() => {
                  if (this.hasToken) {
                    this.$router.push({name: 'login'})
                  } else {
                    this.message = 'If your email is valid, you should receive a password reset email.'
                  }
                })
                .catch(err => {
                  this.errorMessage = (err.response && err.response.data && err.response.data.error) || ''
                })
          })

      return formData
    },
  },

  computed: {
    hasToken() {
      return !!this.token
    }
  }
};
</script>

<style scoped>

</style>
